@import 'styles/theme/theme';

.editDisclaimerModal {
  width: 600px !important;
  &__title {
    font-size: 16px !important;
  }
  &__icon {
    height: 18px !important;
    width: 18px !important;
    fill: #1c1b1f;
  }
  &__closeIcon {
    position: static !important;
  }

  &__main {
    padding: 16px;
    overflow: auto;
    height: 500px;
    padding-top: 0;
    margin-top: 4px;

    @media screen and (max-width: 600px) {
      height: 100%;
      margin-bottom: 70px;
    }
  }
  &__scrollMsg {
    top: 0;
    text-align: right;
    margin-bottom: 14px;
    position: sticky;
    background: white;
  }
  &__policyTitle {
    margin-bottom: 8px;
  }
  &__policyDesc {
    margin-bottom: 14px;
  }
  &__mainAddress {
    margin-top: 14px;
    margin-bottom: 14px;
  }
  &__footer {
    border-top: 1px solid $secondaryGrey;
    padding: 12px 16px;
    position: sticky;
    bottom: 0;
    background: $white;
    border-radius: 0 0 8px 8px;
    display: flex;
    flex-direction: column;
    text-align: right;
    gap: 20px;

    @media screen and (max-width: 600px) {
      position: fixed;
      width: 100%;
    }

    &__actions {
      display: grid;
      grid-template-columns: 25% 75%;
    }
  }
}

.confirmationPolicy {
  &__content {
    padding: 16px;
  }
  &__footer {
    border-top: 1px solid $borderGrey;
    padding: 12px 16px;
    position: sticky;
    bottom: 0;
    background: $white;
    border-radius: 0 0 8px 8px;
    &__actions {
      display: flex;
      justify-content: flex-end;
    }
    &_btn {
      width: 100%;
    }
  }
}

.loadingRedirect {
  padding: 20px;
}

.drawerContainer {
  min-height: 80%;
}
